import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const getFormPartsConfig = (formats) => {
  const [list, displayOptions] = [[], []];

  formats.forEach((format) => {
    list.push(format.id);
    displayOptions.push(format.name);
  })

  return([
    {
      labelText: 'id',
      key: 'id',
      type: 'number',
    },
    {
      labelText: 'カード名',
      key: 'searchable_name',
      type: 'text',
    },
    {
      labelText: '属性',
      key: '_style',
      type: 'checkboxList',
      list: list,
      displayOptions: displayOptions,
    },
    {
      labelText: '特徴',
      key: 'feature',
      type: 'checkboxList',
      list: list,
      displayOptions: displayOptions,
    },
    {
      labelText: '色',
      key: 'regulations.onepiece_format_id',
      type: 'checkboxList',
      list: list,
      displayOptions: displayOptions,
    },
  ])
}

const IndexCards = (props) => {
	const cardTitle = useCardTitle();
  const [cards, setCards] = useState([]);
  const [formats, setFormats] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'DESC',
    },
    regulations: { onepiece_format_id: [1]},
    to_json_option: {
      methods: ['created_on'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/formats`, {}), (response) => {
      setFormats(response.body);
    });
  }, [])

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/cards`, query), (response) => {
      setCards(response.body.records);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/cards/${id}`, (response) => {
      if (response.data.result) {
        const newCards = [];
        const flashMessages = [];
        for (let i = 0; i < cards.length; i++) {
          if (cards[i].id !== id) {
            newCards.push(cards[i]);
          } else {
            flashMessages.push(`id:${cards[i].id} ${cards[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setCards([...newCards]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setCards([...cards]);
      }
    });
	}, [cardTitle])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:カード一覧`}
      tableHeaders={[
        'id',
        'カード名',
        '属性',
        '色',
        'ライフ',
        'コスト',
        'パワー',
        'カウンター',
        '特徴',
        'テキスト',
        'トリガー',
        '編集',
        '削除',
      ]}
      displayRecordKeys={[
        'id',
        'name',
        'style',
        'color',
        'life',
        'cost',
        'power',
        'counter',
        'feature',
        'text',
        'trigger',
      ]}
      records={cards}
      formPartsConfig={getFormPartsConfig(formats)}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexCards