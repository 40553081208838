import React, { useState } from "react";
import styled from "styled-components";
import api from 'Modules/api.js';
import Flash from 'Components/Atoms/Flash';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FileInput = styled.input`
  margin: 10px 0;
`;

const SelectInput = styled.select`
  margin: 10px 0;
  padding: 5px;
`;

const Button = styled.button`
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const UpdateBuyingPrices = () => {
  const [selectedOption, setSelectedOption] = useState(""); // ポケモンやMTGの選択
  const [fileData, setFileData] = useState(null); // CSVファイルの内容
  const [submitValue, setSubmitValue] = useState('更新');

  // CSVファイルを読み込む関数
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      setFileData(content); // ファイル内容を保存
      console.log("CSV Content:", content); // デバッグ用
    };

    reader.readAsText(file); // ファイルをテキストとして読み込む
  };

  // プルダウンメニューでの選択変更
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    if (!fileData) {
      alert("CSVファイルをアップロードしてください！");
      return;
    }
    if (!selectedOption) {
      alert("タイトルを選択してください！");
      return;
    }
    event.preventDefault();
    if (submitValue === '更新中...') { return }
    setSubmitValue('更新中...');
    const data = new FormData();
    data.append("file", fileData);
    data.append("card_title", selectedOption);

    api.httpPost(`/admin/csvs/update_buying_price_from_csv`, data, (response) => {
      console.log(response);
      if (response.data.result) {
        localStorage.setItem('flashMessages', `${selectedOption}の更新に成功しました。`);
        setSubmitValue('更新');
      } else {
        setSubmitValue('更新');
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
      }
    })
  }

  return (
    <Container>
      <Flash />
      <h1>買取表更新</h1>

      <SelectInput value={selectedOption} onChange={handleOptionChange}>
        <option value="">タイトルを選択してください</option>
        <option value="pokemon">ポケモン</option>
        <option value="mtg">MTG</option>
        <option value="yugioh">遊戯王</option>
        <option value="dragon_ball">ドラゴンボール</option>
        <option value="shadow_verse">シャドバ</option>
        <option value="duel_masters">デュエマ</option>
        <option value="onepiece">ワンピース</option>
        <option value="battle_spirits">バトスピ</option>
        <option value="vanguard">ヴァンガード</option>
        <option value="digimon">デジモン</option>
      </SelectInput>

      <FileInput type="file" accept=".csv" onChange={handleFileUpload} />

      <Button onClick={handleSubmit}>{submitValue}</Button>
    </Container>
  );
};

export default UpdateBuyingPrices;
