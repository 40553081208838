import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import useCardTitle from 'Modules/useCardTitle';

const formPartsConfig = [
  {
    labelText: '記事カテゴリー名',
    key: 'name',
    type: 'text',
  },
]

const NewArticleCategory = (props) => {
	const cardTitle = useCardTitle();
  const [articleCategory, setArticleCategory] = useState({
    article_category: {
      name: '',
    }
  });

  const handleInputValue = (event) => {
    setArticleCategory({
      article_category: {
        name: event.target.value,
      },
    });
  };

  const handleSubmit = (event) => {
    api.httpPost(`/admin/${cardTitle.name}/article_categories`, articleCategory, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '作成に成功しました。');
        props.history.push(`/${cardTitle.name}/article_categories`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/article_categories/new`)
      }
    })
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】記事カテゴリー:新規作成`}
      record={articleCategory}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewArticleCategory