import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';
import enviroment from 'Modules/enviroment.js';

const getYesterDay = () => {
  const yesterDay = new Date();
  yesterDay.setDate(yesterDay.getDate() - 1);
  return(yesterDay.toISOString().split('T')[0]);
}

const formPartsConfig = [
  {
    labelText: 'id',
    key: 'id',
    type: 'number',
  },
  {
    labelText: 'アーキタイプ',
    key: 'archetype.name',
    type: 'text',
  },
  {
    labelText: 'フォーマット',
    key: 'format.name',
    type: 'text',
  },
  {
    labelText: '投稿者',
    key: 'user.nick_name',
    type: 'text',
  },
  {
    labelText: '公開状態',
    key: 'is_open',
    type: 'checkboxList',
    displayOptions: ['公開', '非公開'],
    list: ['true', 'false'],
  },
  {
    labelText: '期間（起点）',
    key: 'created_at.min',
    type: 'date',
  },
  {
    labelText: '期間（終点）',
    key: 'created_at.max',
    type: 'date',
  },
]

const IndexDecks = (props) => {
  const defaultQuery = useMemo(() => {
    return({
      is_open: ['true', 'false'],
      archetype: {name: '未入力'},
      user: {nick_name: '運営'},
      created_at: {min: '', max: ''},
			limit: 40,
			sort: {
				key: 'created_at',
				order: 'desc',
			},
      format: {searchable_name: ''},
      associations: ['archetype', 'format', 'user'],
			to_json_option: {
				methods: ['created_on'],
        include: ['archetype', 'format', 'user'],
			},
      page: 1,
    });
  })
	const cardTitle = useCardTitle();
  const [decks, setDecks] = useState([]);
  const [query, setQuery] = useState(defaultQuery);
  const [allCount, setAllCount] = useState(0);

  const fetchAndSetDecks = (q) => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/decks`, q), (response) => {
      setDecks(response.body);
    });
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/decks/count`, {...q, limit: 300}), (response) => {
      setAllCount(response.body);
    });
  }

  useEffect(() => {
    fetchAndSetDecks(query);
  }, [query.page]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query}, event.target.name, event.target.value))
  }, [query, setQuery])

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    fetchAndSetDecks(query);
  }, [query])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:デッキ一覧`}
      tableHeaders={[
        'id',
        'アーキタイプ',
        'フォーマット',
        '投稿者',
        '公開状態',
        '作成日',
        '非公開メモ',
        '編集',
      ]}
      displayRecordKeys={[
        'id',
        'archetype.name',
        'format.name',
        'user.nick_name',
        'is_open',
        'created_on',
        'closed_note',
      ]}
      linkToNewPage={`${enviroment.userFrontOrigin}/onepiece/deck_builder`}
      records={decks}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      allCount={allCount}
    />
  );
}

export default IndexDecks