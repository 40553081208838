import React, {useState, useMemo} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'Modules/colors.js';
import Accordion from 'Components/Molecules/Accordion';
import Paragraph from 'Components/Atoms/Paragraph';
import Anchor from 'Components/Atoms/Anchor';
import useCurrentUrl from 'Modules/useUrl.jsx';
import useCardTitle from 'Modules/useCardTitle';

const SideBar = (props) => {
	const [isOpen, setIsOpen] = useState(true);

	const logout = () => {
		props.auth.signOut();
	}

	const className = isOpen ? props.className : `${props.className} close`;

	const list = useMemo(() => {
		if (!isOpen) {
			return(null);
		}

		return([
			<Accordion key="遊戯王" title="遊戯王" innerComponents={[
				<Link to='/yugioh/configurable_images'>設定画像一覧</Link>,
				<Link to='/yugioh/articles'>記事一覧</Link>,
				<Link to='/yugioh/article_categories'>記事カテゴリー一覧</Link>,
				<Link to='/yugioh/article_tags'>記事タグ一覧</Link>,
				<Link to='/yugioh/archetypes'>アーキタイプ一覧</Link>,
				<Link to='/yugioh/formats'>フォーマット一覧</Link>,
				<Link to='/yugioh/decks'>デッキ一覧</Link>,
				<Link to='/yugioh/cards'>カード一覧</Link>,
				<Link to='/yugioh/unique_cards'>ユニークカード一覧</Link>,
				<Link to='/yugioh/packs'>パック一覧</Link>,
				<Link to='/yugioh/rarities'>レアリティ一覧</Link>,
				<Link to='/yugioh/ocha_products'>おちゃ商品一覧</Link>,
				<Link to='/yugioh/ocha_relations'>おちゃづけ一覧</Link>,
			]}/>,

			<Accordion key="ポケモン" title="ポケモン" innerComponents={[
				<Link to='/pokemon/configurable_images'>設定画像一覧</Link>,
				<Link to='/pokemon/articles'>記事一覧</Link>,
				<Link to='/pokemon/article_categories'>記事カテゴリー一覧</Link>,
				<Link to='/pokemon/article_tags'>記事タグ一覧</Link>,
				<Link to='/pokemon/archetypes'>アーキタイプ一覧</Link>,
				<Link to='/pokemon/formats'>フォーマット一覧</Link>,
				<Link to='/pokemon/decks'>デッキ一覧</Link>,
				<Link to='/pokemon/cards'>カード一覧</Link>,
				<Link to='/pokemon/unique_cards'>ユニークカード一覧</Link>,
				<Link to='/pokemon/packs'>パック一覧</Link>,
				<Link to='/pokemon/rarities'>レアリティ一覧</Link>,
				<Link to='/pokemon/card_conditions'>おちゃ状態一覧</Link>,
				<Link to='/pokemon/ocha_products'>おちゃ商品一覧</Link>,
				<Link to='/pokemon/tier_lists'>Tierリスト一覧</Link>,
				// <Link to='/pokemon/ocha_relations'>おちゃづけ</Link>,
			]}/>,

      <Accordion key="ワンピース" title="ワンピース" innerComponents={[
				<Link to='/onepiece/configurable_images'>設定画像一覧</Link>,
				<Link to='/onepiece/articles'>記事一覧</Link>,
				<Link to='/onepiece/article_categories'>記事カテゴリー一覧</Link>,
				<Link to='/onepiece/article_tags'>記事タグ一覧</Link>,
				<Link to='/onepiece/archetypes'>アーキタイプ一覧</Link>,
				<Link to='/onepiece/formats'>フォーマット一覧</Link>,
				<Link to='/onepiece/decks'>デッキ一覧</Link>,
				<Link to='/onepiece/cards'>カード一覧</Link>,
				<Link to='/onepiece/unique_cards'>ユニークカード一覧</Link>,
				<Link to='/onepiece/packs'>パック一覧</Link>,
				<Link to='/onepiece/rarities'>レアリティ一覧</Link>,
				<Link to='/onepiece/card_conditions'>おちゃ状態一覧</Link>,
				<Link to='/onepiece/ocha_products'>おちゃ商品一覧</Link>,
				<Link to='/onepiece/tier_lists'>Tierリスト一覧</Link>,
				// <Link to='/onepiece/ocha_relations'>おちゃづけ</Link>,
			]}/>,

      <Accordion key="mtg" title="mtg" innerComponents={[
				// <Link to='/mtg/configurable_images'>設定画像一覧</Link>,
				<Link to='/mtg/articles'>記事一覧</Link>,
				<Link to='/mtg/article_categories'>記事カテゴリー一覧</Link>,
				<Link to='/mtg/article_tags'>記事タグ一覧</Link>,
				// <Link to='/mtg/archetypes'>アーキタイプ一覧</Link>,
				// <Link to='/mtg/formats'>フォーマット一覧</Link>,
				// <Link to='/mtg/decks'>デッキ一覧</Link>,
				// <Link to='/mtg/cards'>カード一覧</Link>,
				// <Link to='/mtg/unique_cards'>ユニークカード一覧</Link>,
				// <Link to='/mtg/packs'>パック一覧</Link>,
				// <Link to='/mtg/rarities'>レアリティ一覧</Link>,
				// <Link to='/mtg/card_conditions'>おちゃ状態一覧</Link>,
				// <Link to='/mtg/ocha_products'>おちゃ商品一覧</Link>,
				// <Link to='/mtg/tier_lists'>Tierリスト一覧</Link>,
				// <Link to='/mtg/ocha_relations'>おちゃづけ</Link>,
			]}/>,

      <Accordion key="バトスピ" title="バトスピ" innerComponents={[
				// <Link to='/battle_spirits/configurable_images'>設定画像一覧</Link>,
				<Link to='/battle_spirits/articles'>記事一覧</Link>,
				<Link to='/battle_spirits/article_categories'>記事カテゴリー一覧</Link>,
				<Link to='/battle_spirits/article_tags'>記事タグ一覧</Link>,
				// <Link to='/battle_spirits/archetypes'>アーキタイプ一覧</Link>,
				// <Link to='/battle_spirits/formats'>フォーマット一覧</Link>,
				// <Link to='/battle_spirits/decks'>デッキ一覧</Link>,
				// <Link to='/battle_spirits/cards'>カード一覧</Link>,
				// <Link to='/battle_spirits/unique_cards'>ユニークカード一覧</Link>,
				// <Link to='/battle_spirits/packs'>パック一覧</Link>,
				// <Link to='/battle_spirits/rarities'>レアリティ一覧</Link>,
				// <Link to='/battle_spirits/card_conditions'>おちゃ状態一覧</Link>,
				// <Link to='/battle_spirits/ocha_products'>おちゃ商品一覧</Link>,
				// <Link to='/battle_spirits/tier_lists'>Tierリスト一覧</Link>,
				// <Link to='/battle_spirits/ocha_relations'>おちゃづけ</Link>,
			]}/>,

      <Accordion key="デュエマ" title="デュエマ" innerComponents={[
				// <Link to='/duel_masters/configurable_images'>設定画像一覧</Link>,
				<Link to='/duel_masters/articles'>記事一覧</Link>,
				<Link to='/duel_masters/article_categories'>記事カテゴリー一覧</Link>,
				<Link to='/duel_masters/article_tags'>記事タグ一覧</Link>,
				// <Link to='/duel_masters/archetypes'>アーキタイプ一覧</Link>,
				// <Link to='/duel_masters/formats'>フォーマット一覧</Link>,
				// <Link to='/duel_masters/decks'>デッキ一覧</Link>,
				// <Link to='/duel_masters/cards'>カード一覧</Link>,
				// <Link to='/duel_masters/unique_cards'>ユニークカード一覧</Link>,
				// <Link to='/duel_masters/packs'>パック一覧</Link>,
				// <Link to='/duel_masters/rarities'>レアリティ一覧</Link>,
				// <Link to='/duel_masters/card_conditions'>おちゃ状態一覧</Link>,
				// <Link to='/duel_masters/ocha_products'>おちゃ商品一覧</Link>,
				// <Link to='/duel_masters/tier_lists'>Tierリスト一覧</Link>,
				// <Link to='/duel_masters/ocha_relations'>おちゃづけ</Link>,
			]}/>,

			<Accordion key="共通管理画面" title="共通管理画面" innerComponents={[
				// <Link to='/staffs/sign_up'>新規スタッフ登録</Link>,
				// <Link to='/staffs/sign_in'>スタッフログイン</Link>,
				// <Link to='/staffs/sign_out'>スタッフログアウト</Link>,
        <Link to='/authors'>ライター一覧</Link>,
				<Link to='/custom_images'>画像一覧</Link>,
				<Link to='/inhouse/documents'>社内ドキュメント一覧</Link>,
			]}/>,

			<Accordion key="Danger" title="Danger Zone" innerComponents={[
				<Link to='/test'>テストページ</Link>,
				<Link to='/rush_media_converter'>RushMediaConverter</Link>,
				<Link to='/pokemon/ocha_orders'>ポケモンおちゃ受注一覧</Link>,
			]}/>,
      <Accordion key="Useful" title="お役立ち機能" innerComponents={[
				<Link to='/useful/ConvertCardNameToLink'>カード名リンク変換</Link>,
        <Link to='/useful/UpdateBuyingPrices'>買取表更新</Link>,
			]}/>,
		]);
	}, [isOpen])

	return(
		<div className={className}>
			<div>
				<div onClick={logout}>
					<Paragraph content="ログアウト" />
				</div>
				<div>
					<Anchor href={'/'} content="トップ" addPrefix={false} />
				</div>
				<div onClick= {() => {setIsOpen(!isOpen)}}>
					<Paragraph content={isOpen ? '閉じる' : '開く'} />
				</div>
			</div>
			{list}
		</div>
	);
}

const StyledSideBar = styled(SideBar)`
background-color: ${colors.lightGray};
padding: 60px 0;
width: 220px;
min-width: 220px;
min-height: 800px;

> div:first-child {
	> div {
		padding: 0 2px;
		cursor: pointer;
		margin-left: 4px;
		width: 80px;
		margin-bottom: 20px;
	}

	> div:first-child {
		border: solid 1px black;
	}

	> div:last-child {
		background-color: ${colors.gray};
		> p {
			color: ${colors.white};
		}
	}
}

&.close {
	max-width: 60px;
	min-width: 60px;
}
`;

export default StyledSideBar;