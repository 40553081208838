// TODO:ハードコーディングしたくないかも
// 参考:https://github.com/CardRush-jp/rush-web-staff-dashboard/pull/28#discussion_r1898194696
export default {
	categories: [
		'LEADER',
    'CHARACTER',
		'STAGE',
    'EVENT',
	],
	color: [
		'',
		'赤',
		'緑',
		'青',
		'紫',
		'黒',
		'黄',
		'多色',
	]
}