import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import api from 'Modules/api';

// 特殊コンポーネント
import GlobalStyle        from 'Modules/GlobalStyle';
import NotFound           from 'Components/Pages/Errors/NotFound';
import SideBar            from 'Components/Organisms/SideBar';
import ContentsWrapper    from 'Components/Molecules/ContentsWrapper';
import Dashboards         from 'Components/Pages/Dashboards/Index';
import RushMediaConverter from 'Modules/convertFromRushMedia';
import Test               from 'Components/Pages/Test';
import useAuth            from 'Modules/useAuth.jsx';
import NewStaffSession    from 'Components/Pages/NewStaffSession';
import ScrollToTop from 'Components/Atoms/ScrollToTop';

// Articles
import IndexArticles   from 'Components/Pages/Common/Articles/Index';
import OldNewArticle   from 'Components/Pages/Common/Articles/OldNew';
import OldEditArticle   from 'Components/Pages/Common/Articles/OldEdit';
import NewArticle   from 'Components/Pages/Common/Articles/New';
import EditArticle   from 'Components/Pages/Common/Articles/Edit';
import PreviewArticle   from 'Components/Pages/Common/Articles/Preview';

// ArticleCtegories
import IndexArticleCategories   from 'Components/Pages/Common/ArticleCategories/Index';
import NewArticleCategory   from 'Components/Pages/Common/ArticleCategories/New';
import EditArticleCategory   from 'Components/Pages/Common/ArticleCategories/Edit';

// ArticleTags
import IndexArticleTags   from 'Components/Pages/Common/ArticleTags/Index';
import NewArticleTag   from 'Components/Pages/Common/ArticleTags/New';
import EditArticleTag   from 'Components/Pages/Common/ArticleTags/Edit';

// Archetypes
import IndexArchetypes   from 'Components/Pages/Common/Archetypes/Index';
import NewArchetype   from 'Components/Pages/Common/Archetypes/New';
import EditArchetype   from 'Components/Pages/Common/Archetypes/Edit';

// Cards
import IndexYugiohCards   from 'Components/Pages/Yugioh/Cards/Index';
import NewYugiohCard   from 'Components/Pages/Yugioh/Cards/New';
import EditYugiohCards   from 'Components/Pages/Yugioh/Cards/Edit';

import IndexPokemonCards   from 'Components/Pages/Pokemon/Cards/Index';
import NewPokemonCard   from 'Components/Pages/Pokemon/Cards/New';
import EditPokemonCards   from 'Components/Pages/Pokemon/Cards/Edit';

import IndexOnepieceCards   from 'Components/Pages/Onepiece/Cards/Index';
// import NewOnepieceCard   from 'Components/Pages/Onepiece/Cards/New';
// import EditOnepieceCards   from 'Components/Pages/Onepiece/Cards/Edit';

// CardConditions
import IndexCardConditions   from 'Components/Pages/Common/CardConditions/Index';
import NewCardCondition   from 'Components/Pages/Common/CardConditions/New';
import EditCardCondition   from 'Components/Pages/Common/CardConditions/Edit';

// ConfigurableImages
import IndexConfigurableImages   from 'Components/Pages/Common/ConfigurableImages/Index';
import NewConfigurableImage   from 'Components/Pages/Common/ConfigurableImages/New';
import EditConfigurableImage   from 'Components/Pages/Common/ConfigurableImages/Edit';

// ConfigurableImages
import IndexAuthors   from 'Components/Pages/Authors/Index';
import NewAuthor   from 'Components/Pages/Authors/New';
import EditAuthor   from 'Components/Pages/Authors/Edit';

// CustomImages
import IndexCustomImages   from 'Components/Pages/CustomImages/Index';
import NewCustomImage   from 'Components/Pages/CustomImages/New';
import EditCustomImage   from 'Components/Pages/CustomImages/Edit';

// Decks
import IndexYugiohDecks   from 'Components/Pages/Yugioh/Decks/Index';
import IndexPokemonDecks   from 'Components/Pages/Pokemon/Decks/Index';
import EditPokemonDeck   from 'Components/Pages/Pokemon/Decks/Edit';

import IndexOnepieceDecks   from 'Components/Pages/Onepiece/Decks/Index';
import EditOnepieceDeck   from 'Components/Pages/Onepiece/Decks/Edit';

// Formats
import IndexFormats   from 'Components/Pages/Common/Formats/Index';
import NewFormat   from 'Components/Pages/Common/Formats/New';
import EditFormat   from 'Components/Pages/Common/Formats/Edit';

import IndexYugiohFormats   from 'Components/Pages/Yugioh/Formats/Index';
import NewYugiohFormat   from 'Components/Pages/Yugioh/Formats/New';
import EditYugiohFormat   from 'Components/Pages/Yugioh/Formats/Edit';

// OchaProducts
import IndexOchaProducts   from 'Components/Pages/Common/OchaProducts/Index';
import EditOchaProduct   from 'Components/Pages/Common/OchaProducts/Edit';

// OchaOrders
import IndexOchaOrders   from 'Components/Pages/Common/OchaOrders/Index';
import NewOchaOrder   from 'Components/Pages/Common/OchaOrders/New';

// OchaRelations
import IndexOchaRelations   from 'Components/Pages/Common/OchaRelations/Index';
// import NewOchaRelation   from 'Components/Pages/Common/OchaRelations/New';
// import EditOchaRelation   from 'Components/Pages/Common/OchaRelations/Edit';

// Packs
import IndexPacks   from 'Components/Pages/Common/Packs/Index';
import NewPack   from 'Components/Pages/Common/Packs/New';
import EditPacks   from 'Components/Pages/Common/Packs/Edit';

// Rarities
import IndexRarities   from 'Components/Pages/Common/Rarities/Index';
import NewRarity   from 'Components/Pages/Common/Rarities/New';
import EditRarities   from 'Components/Pages/Common/Rarities/Edit';

// TierList
import IndexTierLists   from 'Components/Pages/Common/TierLists/Index';
import NewTierList   from 'Components/Pages/Common/TierLists/New';
import EditTierList   from 'Components/Pages/Common/TierLists/Edit';

// UniqueCards
import IndexYugiohUniqueCards   from 'Components/Pages/Yugioh/UniqueCards/Index';
import NewYugiohUniqueCard    from 'Components/Pages/Yugioh/UniqueCards/New';
import EditYugiohUniqueCards   from 'Components/Pages/Yugioh/UniqueCards/Edit';

import IndexPokemonUniqueCards   from 'Components/Pages/Pokemon/UniqueCards/Index';
import NewPokemonUniqueCard    from 'Components/Pages/Pokemon/UniqueCards/New';
import EditPokemonUniqueCards   from 'Components/Pages/Pokemon/UniqueCards/Edit';

import IndexOnepieceUniqueCards   from 'Components/Pages/Onepiece/UniqueCards/Index';
import NewOnepieceUniqueCard    from 'Components/Pages/Onepiece/UniqueCards/New';
import EditOnepieceUniqueCards   from 'Components/Pages/Onepiece/UniqueCards/Edit';

// Inhouse::Documents
import NewInhouseDocument from 'Components/Pages/Inhouse/Documents/New';
import IndexInhouseDocuments from 'Components/Pages/Inhouse/Documents/Index';
import ShowInhouseDocument from 'Components/Pages/Inhouse/Documents/Show';
import EditInhouseDocument from 'Components/Pages/Inhouse/Documents/Edit';

import ConvertCardNameToLink from 'Components/Pages/useful/ConvertCardNameToLink';
import UpdateBuyingPrices from 'Components/Pages/useful/UpdateBuyingPrices';

const Wrapper = styled.div`
display: flex;
`;

const App = (props) => {
  const auth = useAuth();

  useEffect(() => {
    api.httpGet('/admin', (response) => {
      if (response.health_check != 'OK') {
        auth.removeSignedIn();
      }
    })
  }, [])

  if (!auth.isSignedIn) { return <NewStaffSession auth={auth} /> }

  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Wrapper>
        <SideBar auth={auth}/>
        <ContentsWrapper>
          <Switch>
            {/* 特殊ページ */}
            <Route exact path='/' component={Dashboards} />
            <Route exact path='/login' component={<NewStaffSession auth={auth} />} />
            <Route exact path='/test' component={Test} />
            <Route exact path='/yugioh/test' component={Test} />
            <Route exact path='/rush_media_converter' component={RushMediaConverter} />

            {/* Articles */}
            <Route exact path='/articles/preview' component={PreviewArticle} />

            <Route exact path='/battle_spirits/articles' component={IndexArticles} />
            <Route exact path='/battle_spirits/articles/new' component={NewArticle} />
            <Route exact path='/battle_spirits/articles/:id/edit' component={EditArticle} />

            <Route exact path='/duel_masters/articles' component={IndexArticles} />
            <Route exact path='/duel_masters/articles/new' component={NewArticle} />
            <Route exact path='/duel_masters/articles/:id/edit' component={EditArticle} />

            <Route exact path='/mtg/articles' component={IndexArticles} />
            <Route exact path='/mtg/articles/new' component={NewArticle} />
            <Route exact path='/mtg/articles/:id/edit' component={EditArticle} />

            <Route exact path='/onepiece/articles' component={IndexArticles} />
            <Route exact path='/onepiece/articles/new' component={NewArticle} />
            <Route exact path='/onepiece/articles/:id/edit' component={EditArticle} />

            <Route exact path='/pokemon/articles' component={IndexArticles} />
            <Route exact path='/pokemon/articles/new' component={OldNewArticle} />
            <Route exact path='/pokemon/articles/:id/edit' component={OldEditArticle} />

            <Route exact path='/yugioh/articles' component={IndexArticles} />
            <Route exact path='/yugioh/articles/new' component={NewArticle} />
            <Route exact path='/yugioh/articles/:id/edit' component={EditArticle} />

            {/* ArticleCtegories */}
            <Route exact path='/battle_spirits/article_categories' component={IndexArticleCategories} />
            <Route exact path='/battle_spirits/article_categories/new' component={NewArticleCategory} />
            <Route exact path='/battle_spirits/article_categories/:id/edit' component={EditArticleCategory} />

            <Route exact path='/duel_masters/article_categories' component={IndexArticleCategories} />
            <Route exact path='/duel_masters/article_categories/new' component={NewArticleCategory} />
            <Route exact path='/duel_masters/article_categories/:id/edit' component={EditArticleCategory} />

            <Route exact path='/mtg/article_categories' component={IndexArticleCategories} />
            <Route exact path='/mtg/article_categories/new' component={NewArticleCategory} />
            <Route exact path='/mtg/article_categories/:id/edit' component={EditArticleCategory} />

            <Route exact path='/onepiece/article_categories' component={IndexArticleCategories} />
            <Route exact path='/onepiece/article_categories/new' component={NewArticleCategory} />
            <Route exact path='/onepiece/article_categories/:id/edit' component={EditArticleCategory} />

            <Route exact path='/pokemon/article_categories' component={IndexArticleCategories} />
            <Route exact path='/pokemon/article_categories/new' component={NewArticleCategory} />
            <Route exact path='/pokemon/article_categories/:id/edit' component={EditArticleCategory} />

            <Route exact path='/yugioh/article_categories' component={IndexArticleCategories} />
            <Route exact path='/yugioh/article_categories/new' component={NewArticleCategory} />
            <Route exact path='/yugioh/article_categories/:id/edit' component={EditArticleCategory} />

            {/* ArticleTags */}
            <Route exact path='/battle_spirits/article_tags' component={IndexArticleTags} />
            <Route exact path='/battle_spirits/article_tags/new' component={NewArticleTag} />
            <Route exact path='/battle_spirits/article_tags/:id/edit' component={EditArticleTag} />

            <Route exact path='/duel_masters/article_tags' component={IndexArticleTags} />
            <Route exact path='/duel_masters/article_tags/new' component={NewArticleTag} />
            <Route exact path='/duel_masters/article_tags/:id/edit' component={EditArticleTag} />

            <Route exact path='/mtg/article_tags' component={IndexArticleTags} />
            <Route exact path='/mtg/article_tags/new' component={NewArticleTag} />
            <Route exact path='/mtg/article_tags/:id/edit' component={EditArticleTag} />

            <Route exact path='/yugioh/article_tags' component={IndexArticleTags} />
            <Route exact path='/yugioh/article_tags/new' component={NewArticleTag} />
            <Route exact path='/yugioh/article_tags/:id/edit' component={EditArticleTag} />

            <Route exact path='/pokemon/article_tags' component={IndexArticleTags} />
            <Route exact path='/pokemon/article_tags/new' component={NewArticleTag} />
            <Route exact path='/pokemon/article_tags/:id/edit' component={EditArticleTag} />

            <Route exact path='/onepiece/article_tags' component={IndexArticleTags} />
            <Route exact path='/onepiece/article_tags/new' component={NewArticleTag} />
            <Route exact path='/onepiece/article_tags/:id/edit' component={EditArticleTag} />

            {/* Archetypes */}
            <Route exact path='/yugioh/archetypes' component={IndexArchetypes} />
            <Route exact path='/yugioh/archetypes/new' component={NewArchetype} />
            <Route exact path='/yugioh/archetypes/:id/edit' component={EditArchetype} />

            <Route exact path='/pokemon/archetypes' component={IndexArchetypes} />
            <Route exact path='/pokemon/archetypes/new' component={NewArchetype} />
            <Route exact path='/pokemon/archetypes/:id/edit' component={EditArchetype} />

            <Route exact path='/onepiece/archetypes' component={IndexArchetypes} />
            <Route exact path='/onepiece/archetypes/new' component={NewArchetype} />
            <Route exact path='/onepiece/archetypes/:id/edit' component={EditArchetype} />

            {/* Cards */}
            <Route exact path='/yugioh/cards' component={IndexYugiohCards} />
            <Route exact path='/yugioh/cards/new' component={NewYugiohCard} />
            <Route exact path='/yugioh/cards/:id/edit' component={EditYugiohCards} />

            <Route exact path='/pokemon/cards'          component={IndexPokemonCards} />
            <Route exact path='/pokemon/cards/new'      component={NewPokemonCard} />
            <Route exact path='/pokemon/cards/:id/edit' component={EditPokemonCards} />

            <Route exact path='/onepiece/cards'          component={IndexOnepieceCards} />
            {/* <Route exact path='/onepiece/cards/new'      component={NewOnepieceCard} />
            <Route exact path='/onepiece/cards/:id/edit' component={EditOnepieceCards} /> */}

            {/* CardConditions */}
            <Route exact path='/yugioh/card_conditions' component={IndexCardConditions} />
            <Route exact path='/yugioh/card_conditions/new' component={NewCardCondition} />
            <Route exact path='/yugioh/card_conditions/:id/edit' component={EditCardCondition} />

            <Route exact path='/pokemon/card_conditions' component={IndexCardConditions} />
            <Route exact path='/pokemon/card_conditions/new' component={NewCardCondition} />
            <Route exact path='/pokemon/card_conditions/:id/edit' component={EditCardCondition} />

            <Route exact path='/onepiece/card_conditions' component={IndexCardConditions} />
            <Route exact path='/onepiece/card_conditions/new' component={NewCardCondition} />
            <Route exact path='/onepiece/card_conditions/:id/edit' component={EditCardCondition} />

            {/* ConfigurableImages */}
            <Route exact path='/yugioh/configurable_images' component={IndexConfigurableImages} />
            <Route exact path='/yugioh/configurable_images/new' component={NewConfigurableImage} />
            <Route exact path='/yugioh/configurable_images/:id/edit' component={EditConfigurableImage} />

            <Route exact path='/pokemon/configurable_images' component={IndexConfigurableImages} />
            <Route exact path='/pokemon/configurable_images/new' component={NewConfigurableImage} />
            <Route exact path='/pokemon/configurable_images/:id/edit' component={EditConfigurableImage} />

            <Route exact path='/onepiece/configurable_images' component={IndexConfigurableImages} />
            <Route exact path='/onepiece/configurable_images/new' component={NewConfigurableImage} />
            <Route exact path='/onepiece/configurable_images/:id/edit' component={EditConfigurableImage} />

            {/* Authors */}
            <Route exact path='/Authors' component={IndexAuthors} />
            <Route exact path='/Authors/new' component={NewAuthor} />
            <Route exact path='/Authors/:id/edit' component={EditAuthor} />

            {/* CustomImages */}
            <Route exact path='/custom_images' component={IndexCustomImages} />
            <Route exact path='/custom_images/new' component={NewCustomImage} />
            <Route exact path='/custom_images/:id/edit' component={EditCustomImage} />

            {/* Decks */}
            <Route exact path='/yugioh/decks' component={IndexYugiohDecks} />
            <Route exact path='/pokemon/decks' component={IndexPokemonDecks} />
            <Route exact path='/pokemon/decks/:id/edit' component={EditPokemonDeck} />

            <Route exact path='/onepiece/decks' component={IndexOnepieceDecks} />
            <Route exact path='/onepiece/decks/:id/edit' component={EditOnepieceDeck} />

            {/* Formats */}
            <Route exact path='/yugioh/formats' component={IndexYugiohFormats} />
            <Route exact path='/yugioh/formats/new' component={NewYugiohFormat} />
            <Route exact path='/yugioh/formats/:id/edit' component={EditYugiohFormat} />

            <Route exact path='/pokemon/formats' component={IndexFormats} />
            <Route exact path='/pokemon/formats/new' component={NewFormat} />
            <Route exact path='/pokemon/formats/:id/edit' component={EditFormat} />

            <Route exact path='/onepiece/formats' component={IndexFormats} />
            <Route exact path='/onepiece/formats/new' component={NewFormat} />
            <Route exact path='/onepiece/formats/:id/edit' component={EditFormat} />

            {/* OchaProducts */}
            <Route exact path='/yugioh/ocha_products'          component={IndexOchaProducts} />
            <Route exact path='/pokemon/ocha_products'          component={IndexOchaProducts} />
            <Route exact path='/onepiece/ocha_products'          component={IndexOchaProducts} />
            <Route exact path='/pokemon/ocha_products/:id/edit'          component={EditOchaProduct} />

            {/* OchaOrders */}
            <Route exact path='/pokemon/ocha_orders'          component={IndexOchaOrders} />
            <Route exact path='/pokemon/ocha_orders/new'          component={NewOchaOrder} />

            {/* OchaRelations */}
            <Route exact path='/yugioh/ocha_relations'          component={IndexOchaRelations} />
            {/* <Route exact path='/yugioh/ocha_relations/new'      component={NewOchaRelation} /> */}
            {/* <Route exact path='/yugioh/ocha_relations/:id/edit' component={EditOchaRelation} /> */}

            <Route exact path='/pokemon/ocha_relations'          component={IndexOchaRelations} />
            {/* <Route exact path='/pokemon/ocha_relations/new'      component={NewOchaRelation} /> */}
            {/* <Route exact path='/pokemon/ocha_relations/:id/edit' component={EditOchaRelation} /> */}

            {/* Packs */}
            <Route exact path='/yugioh/packs' component={IndexPacks} />
            <Route exact path='/yugioh/packs/new' component={NewPack} />
            <Route exact path='/yugioh/packs/:id/edit' component={EditPacks} />

            <Route exact path='/pokemon/packs' component={IndexPacks} />
            <Route exact path='/pokemon/packs/new' component={NewPack} />
            <Route exact path='/pokemon/packs/:id/edit' component={EditPacks} />

            <Route exact path='/onepiece/packs' component={IndexPacks} />
            <Route exact path='/onepiece/packs/new' component={NewPack} />
            <Route exact path='/onepiece/packs/:id/edit' component={EditPacks} />

            {/* Rarities */}
            <Route exact path='/yugioh/rarities' component={IndexRarities} />
            <Route exact path='/yugioh/rarities/new' component={NewRarity} />
            <Route exact path='/yugioh/rarities/:id/edit' component={EditRarities} />

            <Route exact path='/pokemon/rarities' component={IndexRarities} />
            <Route exact path='/pokemon/rarities/new' component={NewRarity} />
            <Route exact path='/pokemon/rarities/:id/edit' component={EditRarities} />

            <Route exact path='/onepiece/rarities' component={IndexRarities} />
            <Route exact path='/onepiece/rarities/new' component={NewRarity} />
            <Route exact path='/onepiece/rarities/:id/edit' component={EditRarities} />

            {/* Tiers */}
            <Route exact path='/pokemon/tier_lists' component={IndexTierLists} />
            <Route exact path='/pokemon/tier_lists/new' component={NewTierList} />
            <Route exact path='/pokemon/tier_lists/:id/edit' component={EditTierList} />

            <Route exact path='/onepiece/tier_lists' component={IndexTierLists} />
            <Route exact path='/onepiece/tier_lists/new' component={NewTierList} />
            <Route exact path='/onepiece/tier_lists/:id/edit' component={EditTierList} />

            {/* UniqueCards */}
            <Route exact path='/yugioh/unique_cards' component={IndexYugiohUniqueCards} />
            <Route exact path='/yugioh/unique_cards/new' component={NewYugiohUniqueCard} />
            <Route exact path='/yugioh/unique_cards/:id/edit' component={EditYugiohUniqueCards} />

            <Route exact path='/pokemon/unique_cards' component={IndexPokemonUniqueCards} />
            <Route exact path='/pokemon/unique_cards/new' component={NewPokemonUniqueCard} />
            <Route exact path='/pokemon/unique_cards/:id/edit' component={EditPokemonUniqueCards} />

            <Route exact path='/onepiece/unique_cards' component={IndexOnepieceUniqueCards} />
            <Route exact path='/onepiece/unique_cards/new' component={NewOnepieceUniqueCard} />
            <Route exact path='/onepiece/unique_cards/:id/edit' component={EditOnepieceUniqueCards} />

            {/* Inhouse::Documents */}
            <Route exact path='/inhouse/documents/new' component={NewInhouseDocument} />
            <Route exact path='/inhouse/documents' component={IndexInhouseDocuments} />
            <Route exact path='/inhouse/documents/:id/edit' component={EditInhouseDocument} />
            <Route exact path='/inhouse/documents/:id' component={ShowInhouseDocument} />


            <Route exact path='/useful/convertCardNameToLink' component={ConvertCardNameToLink} />
            <Route exact path='/useful/updateBuyingPrices' component={UpdateBuyingPrices} />

            <Route exact path='/404' component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </ContentsWrapper>
      </Wrapper>
    </Router>
  );
}

export default App;