import React, { useState } from "react";
import styled from "styled-components";

const TagsWrapper = styled.div`
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

const TagSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const TagSearchInput = styled.input`
  flex: 1;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TagSearchButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const TagItem = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const handleTagChange = (tagId, cardTitle, setArticle) => {
  setArticle((prev) => {
    const currentTags = prev.tag_relations_attributes || [];

    const tagExists = currentTags.some(
      (relation) => relation[`${cardTitle.name}_article_tag_id`] === tagId
    );

    const updatedTags = tagExists
      ? currentTags.filter(
          (relation) => relation[`${cardTitle.name}_article_tag_id`] !== tagId
        )
      : [...currentTags, { [`${cardTitle.name}_article_tag_id`]: Number(tagId) }];
    return { ...prev, tag_relations_attributes: updatedTags };
  });
};

export const ArticleTags = ({ article, cardTitle, articleTags, setArticle }) => {
  const [tagSearch, setTagSearch] = useState("");
  const filteredTags = articleTags.filter((tag) =>
    tag.name.includes(tagSearch)
  );

  return (
    <TagsWrapper>
      <h4>記事タグ</h4>
      <TagSearchWrapper>
        <TagSearchInput
          type="text"
          placeholder="検索欄"
          value={tagSearch}
          onChange={(e) => setTagSearch(e.target.value)}
        />
        <TagSearchButton>検索！</TagSearchButton>
      </TagSearchWrapper>
      <div>
      {filteredTags.map((tag) => (
        <TagItem key={tag.id}>
          <label>
            <input
              type="checkbox"
              checked={article.tag_relations_attributes.some(
                (relation) => relation[`${cardTitle.name}_article_tag_id`] === tag.id
              )} // チェック状態を確認
              onChange={() => handleTagChange(tag.id, cardTitle, setArticle)} // タグIDを渡す
            />
            {tag.name}
          </label>
        </TagItem>
      ))}
      </div>
    </TagsWrapper>
  )
}
