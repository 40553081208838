import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const ToolButton = styled.button`
  width: 100%;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ccc;
  color: #333;
  font-size: 14px;
  flex: 1 0 calc(50% - 10px); /* 横幅を2列に分割 */
  text-align: left; /* テキストを左寄せ */
  position: relative; /* 相対位置を指定 */
  align-items: center; /* 垂直方向で中央寄せ */

  &:hover {
    background-color: #bbb;
  }
`;

const Tips = styled.div`
  position: fixed;
  top: 50%;
  width: auto;
  right: 350px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.95);
  border: 2px solid #f4a261;
  padding: 20px;
  border-radius: 8px;
  white-space: pre-wrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  max-width: 800px;
  text-align: left;
  z-index: 1000;
`;

export const ToolButtonWithTips = ({
  onClick,
  children,
  tipsText,
  tipId,
  visibleTipId,
  handleToggleTips,
}) => {
  const isTipsVisible = visibleTipId === tipId;
  const tipsRef = useRef(null);

  // 外部クリックを検知してTIPSを閉じる
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tipsRef.current && !tipsRef.current.contains(event.target)) {
        handleToggleTips(null); // TIPSを閉じる
      }
    };

    if (isTipsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTipsVisible, handleToggleTips]);

  return (
    <div style={{ position: "relative", flex: "1 0 calc(50% - 10px)" }}>
      <ToolButton
        onClick={(e) => {
          if (!isTipsVisible) onClick();
        }}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{children}</span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleToggleTips(isTipsVisible ? null : tipId);
          }}
          style={{
            marginLeft: "10px",
            cursor: "pointer",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "#f9c74f",
            color: "#333",
            fontWeight: "bold",
            fontSize: "14px",
            transition: "background-color 0.2s",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#f4a261")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#f9c74f")}
        >
          ！
        </span>
      </ToolButton>
      {isTipsVisible && (
        <Tips ref={tipsRef}>
          <p>{tipsText}</p>
        </Tips>
      )}
    </div>
  );
};