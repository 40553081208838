import React, { useState, useEffect, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import useCardTitle from 'Modules/useCardTitle';
import Anchor from 'Components/Atoms/Anchor';
import enviroment from 'Modules/enviroment';
import styled from 'styled-components';
import Paragraph from 'Components/Atoms/Paragraph';

const LinkToDeckBuilder = ({className, deck}) => {
  const recipePageLink = useMemo(() => {
    if (!deck || !deck.closed_note) {
      return null;
    }

    let result = null;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    deck.closed_note?.split(`\n`)?.forEach((line) => {
      if (line.includes('レシピページ')) {
        result = line.match(urlRegex)[0]?.replace('confirm', 'thumbs');
      }
    })

    return(result);
  }, [deck.id])

  if (!recipePageLink) {
    return null;
  }

  return(
    <div className={className}>
      <Anchor
        href={`${enviroment.userFrontOrigin}/onepiece/deck_builder/${deck.id}/edit`}
        content="レシピを編集"
      />
      <Anchor
        href={recipePageLink}
        content="引用元レシピ画像"
      />
    </div>
  );
}

const StyledLinkToDeckBuilder = styled(LinkToDeckBuilder)`
> *:first-child {
  margin-right: 12px;
}
`;

const RecipeImage = ({className, deck}) => {

  const images = deck.recipes.map((recipe) => {
    return(
      <div key={recipe.card.id}>
        <img src={recipe.card.image_source} />
      </div>
    )
  })


  return(
    <div className={className}>
      {images}
    </div>
  )
}

const StyledRecipeImage = styled(RecipeImage)`
width: 70%;
display: flex;
flex-wrap: wrap;
> div {
  width: 10%;
  margin: 0;
  img {
    width: 100%;
  }
}
`;

const getFormPartsConfig = (archetypes, formats, deck) => {

  return([
    {
      labelText: 'アーキタイプ',
      key: 'onepiece_archetype_id',
      type: 'selectBoxWithLocalSearch',
      list: archetypes,
      optionKey: 'name',
      valueKey: 'id',
    },
    {
      labelText: 'フォーマット',
      name: 'onepiece_format_id',
      key: 'onepiece_format_id',
      type: 'selectBoxWithLocalSearch',
      list: formats,
      optionKey: 'name',
      valueKey: 'id',
    },
    {
      labelText: '大会名',
      key: 'tournament_name',
      type: 'text',
    },
    {
      labelText: '大会開催日',
      key: 'tournament_date',
      type: 'date',
    },
    {
      labelText: '引用元',
      key: 'quotation',
      type: 'text',
    },
    {
      labelText: '公開状態',
      key: 'is_open',
      type: 'radioCombi',
      labelText: '公開状態',
      options: ['公開', '非公開'],
      optionValues: ['true', 'false'],
    },
    {
      labelText: '参加人数',
      key: 'tournament_participants_count',
      type: 'number',
    },
    {
      labelText: '非公開メモ',
      key: 'closed_note',
      type: 'textarea',
    },
    {
      type: 'custom',
      component: <StyledLinkToDeckBuilder deck={deck} />
    },
    {
      type: 'custom',
      component: <StyledRecipeImage deck={deck} />
    },
    {
      labelText: `レシピ(合計${deck.recipes_count_sum}枚)`,
      key: 'recipes_text',
      type: 'textarea',
      readOnly: true,
    },
  ])
}

const EditDeck = (props) => {
	const cardTitle = useCardTitle();
  const [deck, setDeck] = useState({
    id: null,
    user_id: null,
    name: null,
    category: null,
    quotation: null,
    note: null,
    is_winning: true,
    tournament_name: null,
    tournament_format: null,
    tournament_date: null,
    score: null,
    closed_note: null,
    is_open: false,
    onepiece_archetype_id: null,
    onepiece_format_id: null,
    tournament_participants_count: -1,
    recipes: [],
  });

  const [archetypes, setArchetypes] = useState([]);
  const [formats, setFormats] = useState([]);

  useEffect(() => {
    const query = {
      to_json_option: {
        include: ['archetype', 'format', {recipes: {include: {card: {
          methods: 'image_source'
        }}}}],
        methods: 'recipes_count_sum',
      },
      associations: ['archetype', 'format', {recipes: 'card'}],
    }

    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/decks/${props.match.params.id}`, query), (response) => {
      const newDeck = {...response.body};
      newDeck.recipes_text = newDeck.recipes.map((recipe) => {
        return(`${recipe.card.name} × ${recipe.count}`);
      }).join('\n');
      setDeck(newDeck);
    });

    api.httpGet(api.addQuery(`/${cardTitle.name}/archetypes`, {limit: 20000}), (response) => {
      setArchetypes(response.body);
    });

    api.httpGet(api.addQuery(`/${cardTitle.name}/formats`, {limit: 20000}), (response) => {
      setFormats(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    event.preventDefault();
    setDeck(setNestedJsonValue({...deck}, event.target.name, event.target.value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    api.httpPatch(`/admin/${cardTitle.name}/decks/${props.match.params.id}`, {deck: deck}, (response) => {
      if (response.data.result) {
        localStorage.setItem('flashMessages', '更新に成功しました。');
        props.history.push(`/${cardTitle.name}/decks`);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        props.history.push(`/${cardTitle.name}/decks/${props.match.params.id}/edit`)
      }
    })
  }

  const formPartConfig = getFormPartsConfig(archetypes, formats, deck);

  if (!deck.id) {
    return(
      <div>
        <Paragraph content="読み込み中です" />
      </div>
    )
  }

  return(
    <DefaultFormTemplate
      pageTitle={`【${cardTitle.name}】デッキ:編集`}
      record={deck}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartConfig}
    />
  );
}

export default EditDeck;