import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'カード名',
    key: 'card.searchable_name',
    type: 'text',
  },
]

const IndexUniqueCards = (props) => {
	const cardTitle = useCardTitle();
  const [uniqueCards, setUniqueCards] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'desc',
    },
    card: {
      searchable_name: '',
    },
    associations: ['card', 'rarity', 'pack'],
    to_json_option: {
      methods: ['created_on', 'image_source'],
      include: ['card', 'rarity', 'pack'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/unique_cards`, query), (response) => {
      setUniqueCards(response.body);
    });
  }, [query]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/unique_cards/${id}`, (response) => {
      if (response.data.result) {
        const newUniqueCards = [];
        const flashMessages = [];
        for (let i = 0; i < uniqueCards.length; i++) {
          if (uniqueCards[i].id !== id) {
            newUniqueCards.push(uniqueCards[i]);
          } else {
            flashMessages.push(`id:${uniqueCards[i].id} の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setUniqueCards([...newUniqueCards]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setUniqueCards([...uniqueCards]);
      }
    });
	}, [uniqueCards])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:ユニークカード一覧`}
      tableHeaders={[
        'id',
        'カード名',
        'レアリティ名',
        'パックコード',
        'カードナンバー',
        '特記',
        'イラストレーター',
        '画像',
        '作成日',
        '編集',
        '削除',
      ]}
      displayRecordKeys={[
        'id',
        'card.name',
        'rarity.name',
        'pack.code',
        'model_number',
        'extra_difference',
        'illustrator',
        'image_source',
        'created_on',
      ]}
      records={uniqueCards}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexUniqueCards