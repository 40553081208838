import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ModalHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ModalCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #aaa;

  &:hover {
    color: #000;
  }
`;

const ErrorList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ErrorMessage = styled.li`
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const ErrorModal = ({ errorMessages, onClose }) => {
  if (errorMessages.length === 0) return null;
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>エラーが発生しました</ModalHeader>
        <ErrorList>
          {errorMessages.map((msg, index) => (
            <ErrorMessage key={index}>{msg}</ErrorMessage>
          ))}
        </ErrorList>
        <ModalCloseButton onClick={onClose}>×</ModalCloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};